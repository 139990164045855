<template>
  <CContainer class="d-flex content-center min-vh-100">
    <CRow>
      <CCol>
        <CCardGroup>
          <CCard class="p-4">
            <CCardBody>
              <CForm>
                <h1>Test</h1>
                <p class="text-muted">Test page</p>
                <p>{{ items }}</p>
              </CForm>
            </CCardBody>
          </CCard>
        </CCardGroup>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import axios from "@/plugins/axios.js";

export default {
  name: 'Test',
  data() {
    return {
      items: []
    }
  },
  mounted() {
    // this.selectedDomain = newDomain;
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.listLoading = true;

      axios
        .get(`plan/subscription/`, {
          params: {
          },
          urlParams: {
          }
        })
        .then(response => {
          this.items = response.data.results;
          this.total_rows = response.data.count;
          this.showLoader = false;
        })
        .catch(error => {
          this.showLoader = false;
          console.log(error);
        })
        .finally(() => (this.listLoading = false));
    }
  }
}
</script>
